// Components
.btn {
  padding: .5rem 2.5rem;
  min-width: 120px;
  border-radius: 2px;
  border-bottom-width: 2px;
  @extend .str-18;
  &.primary {
    background-color: $red;
    color: $white;
    border: 1px solid #9c141b;
    &:hover {
      background-color: #9c141b; } } }


// Header
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  border-top: 3px solid $red;
  border-bottom: 1px solid $border;
  padding: 15px 0 10px;
  z-index: 10;
  .ca-logo {
    float: left;
    width: 400px;
    @media(max-width: $sm) {
      width: 200px; }
    span {
      width: calc(100% - 205px);
      padding-left: 10px;
      &:first-child {
        width: 200px;
        padding-left: 0; }
      &:last-child {
        @media(max-width: $sm) {
          display: none; } } } }

  .ca-navigation {
    float: right;
    width: calc(100% - 400px);
    @media(max-width: $sm) {
      width: calc(100% - 200px); }
    ul {
      @extend %list-none, .tar;
      li {
        display: inline-block;
        position: relative;
        a {
          display: block;
          padding: 0 15px;
          line-height: 42px; }
        &.active {
          &:after {
            position: absolute;
            width: 100%;
            height: 4px;
            background-color: $red;
            left: 0;
            bottom: -11px;
            content: ''; } } } }
    .ca-address {
      border-top: 1px dashed $border;
      padding: 15px 15px 0;
      position: absolute;
      bottom: 0;
      display: none;
      span {
        width: calc(100% - 20px);
        vertical-align: top;
        &:first-child {
          width: 15px; } } }
    @media(max-width: $sm) {
      padding-top: 15px;
      float: none;
      width: 260px;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: $white;
      @include transition(all .4s);
      @include transform(translateX(-260px));
      ul li {
        display: block;
        text-align: left; }
      &.active {
        border-right: 1px solid $border;
        @include transform(translateX(0));
        ul li {
          a {
            padding: 5px 15px; }
          &.active {
            a {
              position: relative;
              color: $white;
              z-index: 10; }
            &:after {
              height: 100%;
              bottom: 0;
              z-index: 9; } } } }
      .ca-address {
        display: block; } } }

  .ca-xs-bars {
    float: right;
    width: 30px;
    display: none;
    @media(max-width: $sm) {
      display: block;
      cursor: pointer; }
    span {
      position: relative;
      display: block;
      width: 30px;
      height: 3px;
      background-color: $red;
      margin: 7px 0;
      @include transition(all .3s); }
    &.active {
      margin-top: 5px;
      span {
        display: none;
        &:first-child {
          display: block;
          @include transform(rotate(45deg));
          top: 5px; }
        &:last-child {
          display: block;
          @include transform(rotate(-45deg));
          bottom: 5px; } } } } }


// Content
section {
  padding: 68px 0 50px;
  @include mobile {
    padding-bottom: 0; }

  .ca-masthead {
    background-color: #eee;
    color: $white;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, .3);
    .slide-item {
      a {
        color: $white; }
      @media(max-width: $sm) {
        height: 500px !important;
        br {
          display: none; } } }
    .slick-dots {
      bottom: 100px;
      @include mobile {
        bottom: 40px; }
      li {
        width: 10px;
        height: 10px;
        background-color: $white;
        border-radius: 50%;
        margin: 0 15px;
        button {
          opacity: 0; }
        &.slick-active {
          background-color: $red; } } } }

  .ca-img-product {
    padding: 65% 0 0;
    background-color: #eee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &.no-padd {
      padding: 0; }
    &.cover {
      background-size: cover; } }

  #product {
    border-top: 1px solid $border; }

  #find-me {
    position: relative;
    background-color: #eee;
    border-top: 1px solid $border;
    overflow: hidden;
    .container {
      @media(max-width: $md) {
        width: 100%;
        padding: 0; } }
    #map {
      position: absolute;
      width: 150%;
      height: 470px;
      left: -50%;
      @media(min-width: 1800px) {
        width: 110%;
        left: -10%; }
      @media(min-width: 1400px) and (max-width: 1800px) {
        width: 120%;
        left: -20%; }
      @include tabletPotrait {
        width: 130%;
        left: -30%; }
      @include mobile {
        position: relative;
        height: 300px;
        width: 100%;
        left: 0; }
      .maps_labels {
        display: block;
        padding-top: 30px; }
      iframe {
        width: 100%;
        height: 100%; } }
    .ptd40 {
      background-color: $white;
      padding-left: 30px;
      @include mobile {
        padding-right: 30px; } }
    .ca-address {
      ul {
        @extend %list-none; }
      span {
        width: calc(100% - 30px);
        vertical-align: top;
        &:first-child {
          width: 15px;
          padding-top: 2px;
          margin-right: 5px; } } } } }


// Footer
footer {
  @extend .lig-14;
  border-top: 1px solid $border;
  background-color: $white;
  padding: 15px 0 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
  @include transition(all .3s);
  @include transform(translateY(53px));
  @include mobile {
    position: relative;
    padding-bottom: 15px;
    @include transform(translateY(0));
    .tac {
      text-align: left; } }

  &.active {
    @include transform(translateY(0)); }
  .ca-btn-back {
    display: inline-block;
    height: 53px;
    width: 53px;
    border: 1px solid $border;
    border-bottom: 0;
    @extend .tac, .reg-24;
    margin-top: -16px;
    color: $grey;
    @include transition(all .3s);
    @include mobile {
      position: fixed;
      right: 15px;
      top: 25px;
      border-bottom: 1px solid $border;
      text-align: center; }
    &:hover {
      cursor: pointer;
      background-color: $red;
      color: $white;
      border-color: $red; }
    .fa {
      line-height: 47px; } } }

// Ads
.ca-ads {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.77);
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  @include transition(all .5s);
  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 99; }
  .table-ds {
    display: table;
    width: 100%;
    height: 100%;
    .table-cell {
      display: table-cell;
      vertical-align: middle;
      .ca-modal-ads {
        @extend .tac;
        background-color: $white;
        border: 1px solid $border;
        padding: 30px;
        max-width: 600px;
        margin: 0 auto;
        position: relative;
        .close {
          position: absolute;
          width: 30px;
          height: 30px;
          top: 0;
          right: 0;
          padding-top: 5px;
          @extend .reg-36;
          line-height: 30px; } } } } }
