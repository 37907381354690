// Animate
@mixin transition($val) {
  transition: $val;
  -webkit-transition: $val;
  -moz-transition: $val; }

@mixin transform($val) {
  transform: $val;
  -webkit-transform: $val;
  -moz-transform: $val;

 }  // Device
@mixin mobilexs {
  @media screen and (max-width: $xxs) {
    @content; } }

@mixin mobile {
  @media screen and (max-width: $xs) {
    @content; } }

@mixin tabletPotrait {
  @media screen and (min-width: $xs) and (max-width: $sm) {
    @content; } }

@mixin tabletLandscape {
  @media screen and (min-width: $sm) and (max-width: $md) {
    @content; } }

@mixin laptopScreen {
  @media screen and (min-width: $md) and (max-width: $lg) {
    @content; } }

@mixin largeScreen {
  @media screen and (min-width: $lg) {
    @content; } }

%list-none {
  list-style: none;
  padding: 0;
  margin: 0; }
