// Variable Color
$red : #c61e27;
$white : #ffffff;
$black : #231f20;
$grey  : #777777;
$border : #E2E2E2;

// Breakpoint
$xxs  : 554px;
$xs   : 767px;
$sm   : 992px;
$md   : 1200px;
$lg   : 1400px;
$xl   : 1600px;
$xxl  : 2100px;

// Mixin
@import "cores/mixin";

// Plugins
@import "../plugins/bootstrap/dist/css/bootstrap.min.css";
@import "../plugins/font-awesome/css/font-awesome.min.css";
@import "../plugins/slick-carousel/slick/slick.css";
@import "../plugins/slick-carousel/slick/slick-theme.css";

// Cores
@import "cores/base";
@import "cores/fonts";
@import "cores/utilities";

// Generals
@import "templates/generals";
