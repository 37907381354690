// Font family

$ft-fam : 200, 300, 400, 500;
$ft-wgt : Light, Regular, Semibold, Bold;

/* Font Faces Base */
@for $i from 1 through length($ft-fam) {
  $src: #{"../fonts/proximanova/ProximaNova-"}#{nth($ft-wgt, $i)};
  @font-face {
    font-family : "Proxima Nova";
    src         : local("Proxima Nova");
    src         : url("#{$src}.eot");
    src         : url('#{$src}.eot?#iefix') format('embedded-opentype'), url('#{$src}.woff') format('woff'), url('#{$src}.ttf') format('truetype'), url('#{$src}.svg#svgFontName') format('svg');
    font-style  : normal;
    font-weight : nth($ft-fam, $i); } }

// Typhography
@mixin build-typography($prefix:'') {
  h1, h2, h3, h4, h5, h6, strong, b {
    font-weight: normal; }

  /* Font Sizes */
  $ft-sizes : 72 65 60 56 54 50 48 45 42 38 36 34 32 30 28 26 25 24 22 21 20 18 16 15 14 13 12 11 10;
  @for $i from 1 through length($ft-sizes) {

    // .lig for light font-weight
    .#{$prefix}lig-#{nth($ft-sizes, $i)} {
      font-size   : #{nth($ft-sizes, $i)}px;
      line-height : #{nth($ft-sizes, $i) + 8}px;
      font-weight : 200;

      @if (nth($ft-sizes, $i)>24) {
        @include mobile {
          font-size   : (77px/100) * nth($ft-sizes, $i);
          line-height : 1.2; } } }

    // .reg for regular/normal font-weight
    .#{$prefix}reg-#{nth($ft-sizes, $i)} {
      font-size   : #{nth($ft-sizes, $i)}px;
      line-height : #{nth($ft-sizes, $i) + 8}px;
      font-weight : 300;

      @if (nth($ft-sizes, $i)>24) {
        @include mobile {
          font-size   : (77px/100) * nth($ft-sizes, $i);
          line-height : 1.2; } } }

    // .smb for semibold font-weight
    .#{$prefix}smb-#{nth($ft-sizes, $i)} {
      font-size   : #{nth($ft-sizes, $i)}px;
      line-height : #{nth($ft-sizes, $i) + 8}px;
      font-weight : 400;

      @if (nth($ft-sizes, $i)>24) {
        @include mobile {
          font-size   : (77px/100) * nth($ft-sizes, $i);
          line-height : 1.2; } } }

    // .str for strong/bold font-weight
    .#{$prefix}str-#{nth($ft-sizes, $i)} {
      font-size   : #{nth($ft-sizes, $i)}px;
      line-height : #{nth($ft-sizes, $i) + 8}px;
      font-weight : 500;

      @if (nth($ft-sizes, $i)>24) {
        @include mobile {
          font-size   : (77px/100) * nth($ft-sizes, $i);
          line-height : 1.2; } } } }

  /* Font Styles */
  .#{$prefix}lig {
    font-weight : 200; }

  .#{$prefix}reg {
    font-weight : 300; }

  .#{$prefix}smb {
    font-weight : 400; }

  .#{$prefix}str {
    font-weight : 500; }

  .#{$prefix}i {
    font-style : italic; }

  .#{$prefix}ls {
    letter-spacing : 2px; }

  .#{$prefix}upper {
    text-transform: uppercase; }

  .#{$prefix}normal {
    text-transform: none; }

  .#{$prefix}fc-underline {
    text-decoration: underline; }

  .tall {
    line-height : 1.9; }

  .slim {
    line-height : 1.2; }

  .fc-huge {
    font-size   : 130px;
    line-height : 140px;
    font-weight : 200; }

  // Article
  article {
    h1 {
      font-weight: inherit;
      font-size: 2em; }
    h2 {
      font-size: 1.5em;
      font-weight: inherit; }
    h3 {
      font-size: 1.17em;
      font-weight: inherit; }
    h4 {
      font-size: 1em;
      font-weight: inherit; }
    h5 {
      font-size: 0.83em;
      font-weight: inherit; }
    h6 {
      font-size: 0.75em;
      font-weight: inherit; }
    p {
      font-weight: inherit;
      margin: 0 0 1em;
      &:last-child {
        margin: 0; }
      img {
        width: 100%;
        height: auto; } }
    .indent p {}
    margin: 0;
    + p {
      text-indent: 2em; }
    p {
      word-break: break-all;
      word-break: break-word;
      &:last-child {
        margin-bottom: 0; } }
    a {
      text-decoration: underline;
      &:visited {
        text-decoration: underline; }
      &:hover {
        text-decoration: none; } }
    blockquote {
      font-style: italic;
      &:before {
        content: "\201C";
        display: inline-block;
        padding-right: 0.4em; } }
    li {
      ul, ol {
        margin: 0 1.5em; } }
    ul, ol {
      margin: 0 1.5em 1.5em 1.5em; }
    ul {
      list-style-type: disc;
      ul {
        list-style-type: circle; } }
    ol {
      list-style-type: decimal;
      ol {
        list-style: upper-alpha;
        ol {
          list-style: lower-roman;
          ol {
            list-style: lower-alpha; } } } }
    li {
      margin-bottom: 10px;
      line-height: 20px; }
    &.two-col-article {
      -webkit-column-count: 2 /* Chrome, Safari, Opera */;
      -moz-column-count: 2 /* Firefox */;
      column-count: 2;
      @include mobile {
        -webkit-column-count: 1 /* Chrome, Safari, Opera */;
        -moz-column-count: 1 /* Firefox */;
        column-count: 1; } } } }

@include build-typography();
