// Basic
*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

html, body {
  font-family : "Proxima Nova", sans-serif;
  @extend .reg-16;
  color: $black;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden; }

// Hyperlink Default
a {
  color: $black;
  text-decoration: none;
  outline: none;
  @include transition(all .3s);
  &:hover, &:focus, &:active {
    text-decoration: none;
    color: $red;
    outline: none; } }

// Elem inline
span {
  display: inline-block;
  vertical-align: middle; }

// Header Tags Default
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 20px; }

// hr
hr {
  border-color: $border;
  &.red {
    width: 100px;
    height: 2px;
    background-color: $red;
    display: inline-block;
    margin: 0 0 20px; } }

// table-ds
.g-table {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  .g-table-cell {
    display: table-cell;
    vertical-align: middle; } }

.rel {
  position: relative; }

/* Row Hack */
.row.no-gutter {
  margin-right: 0px;
  margin-left: 0px; }

.row.no-gutter > [class*='col-'],
.row.no-gutter > [class*='col-'] > [class*='col-'] {
  padding-right: 0px;
  padding-left: 0px;
  position: inherit; }
